<template>
    <!-- <v-container>
        <v-row>
            <v-col>
                <v-card v-for="(item, index) in informacion" :key="index" class="mb-2">
                    <v-card-subtitle class="primary--text text--lighten-1">{{ item.TipoTran }}</v-card-subtitle>
                    <v-card-text>
                        <v-row>
                            <v-col cols="6" class="py-0 text-caption">
                                Monto:
                            </v-col>
                            <v-col cols="6" class="py-0 text-caption">
                                {{ '$' + item.ValTran }}
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" class="py-0 text-caption">
                                Fecha:
                            </v-col>
                            <v-col cols="6" class="py-0 text-caption">
                                {{ getFecha(item.FechaTran) }}
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container> -->
    <v-container>
        <v-card elevation="2" v-for="(transaccion, index) in transacciones" v-bind:key="index" class="mb-4">
            <v-card-subtitle class="primary--text text--lighten-1">{{ transaccion.TipoTran }}</v-card-subtitle>
                <v-card-text>
                    <v-row>
                        <v-col cols="6" class="py-0 text-caption">
                            Plan:
                        </v-col>
                        <v-col cols="6" class="py-0 text-caption">
                            {{ transaccion.Plan }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" class="py-0 text-caption">
                            Numero:
                        </v-col>
                        <v-col cols="6" class="py-0 text-caption">
                            {{ transaccion.Numero }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" class="py-0 text-caption">
                            Monto:
                        </v-col>
                        <v-col cols="6" class="py-0 text-caption">
                            {{ '$' + transaccion.ValTran }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6" class="py-0 text-caption">
                            Fecha:
                        </v-col>
                        <v-col cols="6" class="py-0 text-caption">
                            {{ getFecha(transaccion.FechaTran) }}
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn 
                        block
                        color="primary"
                        v-if="transaccion.TipoTran ==='Tienda' && transaccion.Tipo!=='Succeeded'"
                        @click="getUrl(transaccion.Referencia)"
                        >Descargar recibo</v-btn>
                </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>
// export default {
//     data(){
//         return{
//             informacion: []
//         }
//     },
//     mounted(){
//         this.getPagos().then(d=>{
//             this.informacion = d
//         })
//     },
//     methods:{
//         getPagos: async function(){
//             const body = {
//                 opcion: 24,
//                 id: localStorage.getItem('id')
//             }
//             const params = {
//                 body: JSON.stringify(body),
//                 method: 'post'
//             }
//             const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php', params)
//             const data = await response.json()
//             return data
//         },
//         getFecha(s){
//             const d = new Date(Date.parse(s))
//             d.setDate(d.getDate() + 1)
//             return d.toLocaleDateString()
//         }
//     },
//     created(){
//       this.$emit('muestra', false)
//     }
// }
export default {
    data(){
        return{
            transacciones: []
        }
    },
    created(){
        this.$emit('muestra', false)
        this.getTransac().then((data)=>{
            this.transacciones = data.sort((a,b)=>{ return b.ID-a.ID})
            while (this.transacciones.length>5) {
                this.transacciones.pop()
            }
            this.getNombrePlan()
        })
    },
    methods:{
        async getTransac(){
            const body = {
                opcion: 24,
                id: localStorage.getItem('id')
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }
            const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php', params)
            const data = response.json()
            return data
        },
        getUrl(ref){
            window.location.href = 'https://dashboard.openpay.mx/paynet-pdf/mzhbovbitqehnda8gejf/' + ref
        },
        getFecha(f){
            const d = new Date(Date.parse(f))
            return d.toLocaleDateString()
        },
        async setnombrePlan(elemento, index){
            const body = {
                opcion: 37,
                idAltan: elemento.Plan
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }
            const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php', params)
            const data = await response.json()
            if (data.length > 0) {
                this.transacciones[index].Plan = data[0].Nombre
                return data[0].Nombre
            }else{
                this.transacciones[index].Plan = 'Nombre no encontrado'
                return 'Nombre no encontrado'
            }
        },
        getNombrePlan(){
            this.transacciones.forEach((elemento, index)=>{
                this.setnombrePlan(elemento, index)
            })
        }
    }
}
</script>