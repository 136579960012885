<template>
<v-container>
    <v-form action="#" id="payment-form">
        <input type="hidden" name="token_id" id="token_id">
        <input type="hidden" name="amount" id="amount" v-model="am">
        <input type="hidden" name="description" id="description" v-model="desc">
        <v-card>
            <v-card-title>Tarjeta de credito o debito</v-card-title>
            <v-row>
                <v-col>
                    <div class="card-expl" style="width:100%">
                        <div class="credit" style="float:left;width:auto">Tarjetas de credito</div>
                        <div class="debit" style="float:right;width:auto;padding-right:15px">Tarjetas de debito</div>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="mx-auto py-0">
                    <v-text-field outlined clearable label="Nombre Titular" data-openpay-card="holder_name" v-model="titular"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="mx-auto py-0">
                    <v-text-field outlined clearable label="Número de tarjeta" data-openpay-card="card_number" maxlength="16" v-model="tarjeta" v-bind:error="!tarjetaValida"></v-text-field>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="1"></v-col>
                <v-col cols="5" class="mx-auto py-0">
                    <v-text-field outlined clearable label="Mes" data-openpay-card="expiration_month" maxlength="2" v-model="mes" v-bind:error="!expiracionValida"></v-text-field>
                </v-col>
                <v-col cols="5" class="mx-auto py-0">
                    <v-text-field outlined clearable label="Año" data-openpay-card="expiration_year" maxlength="2" v-model="ano" v-bind:error="!expiracionValida"></v-text-field>
                </v-col>
                <v-col cols="1"></v-col>
            </v-row>

            <v-row>
                <v-col cols="10" class="mx-auto py-0">
                    <v-text-field outlined clearable label="3 Digitos" data-openpay-card="cvv2" maxlength="4" v-model="codigo" v-bind:error="!cvcValida"></v-text-field>
                </v-col>
            </v-row>

            <v-row class="mb-7">
                <v-col cols="10" class="mx-auto py-0">

                <div class="openpay"><div class="logo text-center">Transacciones realizadas vía:</div>
                    <div class="shield">Tus pagos se realizan de forma segura con encriptación de 256 bits</div>
                </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="10" class="mx-auto py-0 mb-7">
                    <v-btn block @click="Pagar" color="primary" :disabled="dialog" id="pay-button">Pagar</v-btn>
                    <v-progress-linear v-if="dialog" indeterminate color="primary" class="mb-0"></v-progress-linear>
                </v-col>
            </v-row>
            <v-snackbar
                rounded="pill"
                v-model="snackbar"
                min-width="0px"
                timeout="2000"
                >
                {{mensajeSnack}}
            </v-snackbar>
        </v-card>
    </v-form>

</v-container>
</template>

<script>
export default {
    data(){
        return{
            titular: '',
            tarjeta: '',
            mes: '',
            ano: '',
            codigo: '',
            deviceSessionId: '',
            planId: '1',
            am: '50',
            desc: 'Plan de prueba',
            btnpagar: true,
            tarjetaValida: true,
            expiracionValida: true,
            cvcValida: true,
            tokenid2: '',
            dialog: false,
            snackbar: false,
            mensajeSnack: ''
        }
    },
    created(){
        this.$emit('muestra', false)
    },
    methods:{
        Pagar(event){
            this.dialog = true
            if (this.validar()) {
                this.CreateTkn()
                OpenPay.token.extractFormAndCreate('payment-form',(r)=>{

                    this.hacerCargo(r).then((resp)=>{
                        if (resp.estatus) {
                                this.GuardarTransaccion().then(d=>{
                                    
                                    this.addTelefono().then((t)=>{
                                        if (t.effectiveDate) {
                                            this.mensajeSnack = 'Cargo Realizado'
                                            this.snackbar = true
                                            setTimeout(()=>{
                                                this.$router.push('/Recargas')
                                            },2000)
                                            // this.$router.push('/Recargas')
                                        }else{
                                            this.mensajeSnack = 'Hubo un error al activar el telefono'
                                            this.snackbar = true
                                            // this.$router.push('/Recargas')
                                            setTimeout(()=>{
                                                this.$router.push('/Recargas')
                                            },2000)
                                        }
                                    })
        
                                },(err)=>{

                                    this.addTelefono().then((t)=>{
                                        if (t.effectiveDate) {
                                            this.mensajeSnack = 'Cargo Realizado correctamente'
                                            this.snackbar = true
                                            // this.$router.push('/Recargas')
                                            setTimeout(()=>{
                                                this.$router.push('/Recargas')
                                            },2000)
                                        }else{
                                            this.mensajeSnack = 'Hubo un error al activar el telefono'
                                            this.snackbar = true
                                            setTimeout(()=>{
                                                this.$router.push('/Recargas')
                                            },2000)
                                            // this.$router.push('/Recargas')
                                        }
                                    })

                                })
                                    
                        }else{
                            this.dialog = false
                            this.mensajeSnack = resp.error
                            this.snackbar = true
                        }
                    })
    
                },(e)=>{
                    this.dialog = false
                    this.mensajeSnack = 'La tarjeta fue rechazada'
                    this.snackbar = true
                })
            }else{
                this.dialog = false
                this.mensajeSnack = 'Verifica los datos'
                this.snackbar = true
            }
        },
        validar(){
            this.tarjetaValida = OpenPay.card.validateCardNumber(this.tarjeta)
            this.expiracionValida = OpenPay.card.validateExpiry(this.mes, this.ano)
            this.cvcValida = OpenPay.card.validateCVC(this.codigo)
            
            return this.tarjetaValida && this.expiracionValida && this.cvcValida
        },
        hacerCargo: async function(r){
            const body = {
                nombre: localStorage.getItem('nombre'),
                apellido: localStorage.getItem('apellido'),
                celular: this.$route.params.numero,
                email: localStorage.getItem('email'),
                sourceId: r.data.id, //Token
                amount: this.$route.params.plan.Costo,
                descripcion: this.$route.params.plan.Nombre,
                deviceSession: this.deviceSessionId,
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }

            const response = await fetch('https://maifon.mx/Conexion/MakeCharge.php', params)
            const data = await response.json()
            return data
        },
        CreateTkn(){
            OpenPay.setId('mzhbovbitqehnda8gejf')
            OpenPay.setApiKey('pk_5740e77beedc453db4b6a063448c72ee')
            OpenPay.setSandboxMode(false)
            this.deviceSessionId = OpenPay.deviceData.setup('payment-form','token_id')
        },
        GuardarTransaccion: async function(){
            const body = {
                opcion: 36,
                IdUser: localStorage.getItem('id'),
                Numero: this.$route.params.numero,
                Plan: this.$route.params.plan.IdAltan,
                TipoTran: this.$route.params.metodo,
                ValTran: this.$route.params.plan.Costo,
            }
            const params = {
                body: JSON.stringify(body),
                method: 'post'
            }
            const response = await fetch('https://maifon.mx/Conexion/crudCrecer.php', params)
            const data = await response.json()
            return data
        },
        addTelefono: async function(){
            const body = {
                celular: this.$route.params.numero,
                token: localStorage.getItem('token'),
                oferta: this.$route.params.plan.IdAltan,
            }
            var datos = {
                body: JSON.stringify(body),
                method: 'POST'
            }

            const response = await fetch('https://maifon.mx/api/addPlan.php',datos)
            const data = await response.json()
            return data
        },
    }
}
</script>

<style>

.openpay div.logo {
    background-image: url("../assets/openpay.png");
    background-position: center bottom;
    background-repeat: no-repeat;
    height: 55px;
}
.openpay div.shield {
    background-image: url("../assets/security.png");
    background-position: left;
    background-repeat: no-repeat;
    padding: 20px 0 0 40px;
}
.card-expl div {
    background-position: left 45px;
    background-repeat: no-repeat;
    height: 70px;
    padding-top: 10px;
}
.card-expl div.debit {
    background-image: url("../assets/cards2.png");
}
.card-expl div.credit {
    background-image: url("../assets/cards1.png");
    margin-left: 10px;
}

</style>